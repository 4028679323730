@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:regular,bold,italic&subset=latin,latin-ext');

body {
  background-color: #202020 !important;
  font-family: 'Poppins', sans-serif;
}

.container-screen {
  padding-left: 100px;
  padding-right: 100px;
}

.container-fluid {
  padding: auto 100px !important;
}

a {
  cursor: pointer;
}

.homePage {
  height: 100vh;
}

.home {
  background-image: url('./assets/images/bg1.319b81c0cf21305c.jpg');
  height: 100vh;
}

.signuppage {
  background-color: #000000c7;
}

.homeimagebg {
  background-color: #0000008f;
}

.react-tel-input .country-list .country-name {
  color: #000000;
}

.home .homeimagebg .homepagecontent .homeheading h1 {
  font-size: 4rem;
  text-transform: capitalize;
  text-align: center;
  color: #ffffff;
  font-weight: 700;
}

.home .homeimagebg .homepagecontent .homeheading p {
  font-size: 1rem;
  text-transform: capitalize;
  text-align: center;
  color: #ffffff;
  font-weight: 600;
}

.loginform h6 {
  font-size: 40px;
  line-height: 40px;
  text-align: start;
  font-weight: 700;
}

.react-tel-input .form-control {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  background: transparent !important;
  border: 1px solid rgba(255, 255, 255, .2705882353) !important;
  height: 50px !important;
  color: #ffffff;
  width: 100% !important;
  font-size: 18px !important;
}

.react-tel-input .flag-dropdown {
  border: 1px solid rgba(255, 255, 255, 0) !important;
  background: transparent !important;
}

.form-control:focus {
  color: #fff !important;
  background-color: var(--bs-body-bg);
  border-color: #86b7fe;
  outline: 0;
  box-shadow: none !important;
}

.get-start-btn {
  background-color: #f97316;
  border: none;
  border-radius: 6px;
  height: 50px;
  color: #fff;
  font-weight: 600;
  width: 100%;
}

/* =====ott page======== */
.ott-container .banner-carousel .banner img {
  width: 100%;
}

.play-icon-banner {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-icon-content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.play-icon-content span svg {
  width: 40px;
  height: 40px;
}

.supersingerbannmer img {
  width: 100%;
}

.banner-box .banner-link img {
  width: 100%;
}

/* ==========category Page======== */
.category-container .sectiontitle {
  color: #ffffff;
  line-height: 25px;
  font-weight: 700;
  font-size: 28px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 50px;
}

.videos-slider .video-box img {
  height: 100%;
  width: 100%;
}

/* ========video page ======== */
.ott-videos .videos .v-content .v-title h2 {
  font-weight: 700;
  font-size: 42px;
  color: #fff;
  text-transform: uppercase;
}

.ott-videos .videos {
  gap: 50px;
}

.ott-videos {
  padding-top: 50px;
  padding-bottom: 50px;
}

.v-content .v-duration-category {
  color: #ffffff87;
  gap: 20px;
  padding: 30px 0px;
}

.v-content .v-description p {
  color: #ffffff;
}

.v-cast-section h6 {
  font-size: 18px;
  font-weight: 700;
  line-height: 28px;
}

.hidden {
  display: none;
}

.advertisement-overlay iframe {
  min-height: 315px;
}

.skip-btn button{
  background: #000;
  color: #fff;
  border: navajowhite;
  padding: 4px 17px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 600;
}


/*===================Header css============= */

.header {
  background-color: #202020ed;
}

.sticky-header {
  position: sticky;
  z-index: 99999;
  top: 0;
  width: 100%;
}

.burger-menu {
  display: none;
}

.logo {
  cursor: pointer;
}

.mobile-logo {
  display: none;
}

.header .logo img {
  height: 50px;
}

.header .header-menu ul {
  gap: 20px;
  margin-bottom: 0;
}

.header .header-menu ul li {
  list-style: none;
  color: #ffffff !important;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

/* .header .header-menu ul li a:hover,
.header .header-menu ul li .hover-color:hover {
  color: #ffffff82 !important;
} */

.header .header-menu ul li a {
  color: #ffffff !important;
  text-decoration: none !important;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-menu.show {
  display: block;
}

.nav-item.dropdown:hover .dropdown-menu {
  display: block;
}


.user span {
  /* background: #f97316; */
  padding: 8px;
  border-radius: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user-icon:hover,
.dropdown-item:hover {
  background-color: #f97316 !important;
  color: #ffffff !important;
}

.header-menu .dropdown-item:hover {
  border-radius: 5px;
}

.header-menu .dropdown-item {
  color: #ffffff;
  font-weight: 600;
  margin: 6px 0px;
}

.moremenulist {
  padding-left: 6px;
  padding-right: 6px;
}

.header-menu .dropdown-menu {
  background: #000;
}

/* ==========live tv =============== */
.live-videos {
  width: 100%;
  color: #ffffff;
  padding: 10px 100px 40px;
}

.livevideo {
  width: 100%;
}

.tv-box {
  background-color: #000000;
  /* max-height: 80vh; */
  width: 85%;
}

/* ========payment gateway============= */
.paymentgateway-main {
  padding: 60px 100px;
}

.payment-option {
  gap: 20px;
}

.paymentgateway-main .payment-heading h2 {
  font-size: 30px;
  line-height: 30px;
  font-weight: 700;
}

.payment-option .method {
  display: flex;
  gap: 10px;
}

.payment-option .method label {
  border: 1px solid #ffffff;
  padding: 5px 20px;
  border-radius: 30px;
  cursor: pointer;
}

.sidetext {
  font-size: 16px;
  font-weight: 600;
}

.plan-amount {
  padding: 20px 30px;
  background: #ffffff0d;
  border-radius: 10px;
}

.borderb {
  height: 1px;
  background-color: #fff;
  width: 100%;
}

.paybtn button {
  border: none;
  border-radius: 40px;
  padding: 6px 20px;
  font-size: 16px;
  font-weight: 600;
  background: #f97316;
  color: #ffffff;
}


/* ============subscription ============ */

.subscription-page .subscription-box {
  background: linear-gradient(313deg, #ce5120, #f08018);
  padding: 40px 20px;
  max-width: 360px;
  border-radius: 15px;
}

.subscription-page {
  padding: 40px 100px;
}

.subscription-box h2 {
  position: relative;
  padding: 0;
  color: #fff;
  font-size: 28px;
  z-index: 2;
  font-weight: 700;
}

.subscription-box h5 {
  position: relative;
  color: #fff;
  font-size: 20px;
  z-index: 2;
  font-weight: 700;
}

.subscription-page .subscription-box .show-services ul {
  margin: 20px 0px;
  padding-left: 0px;
}

.subscription-page .subscription-box .show-services ul li img {
  padding-right: 5px;
}

.subscription-page .subscription-box .show-services ul li {
  margin: 0 0 10px;
  padding: 0;
  list-style: none;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
}

.subscribe-btn button {
  position: relative;
  z-index: 2;
  background: #fff;
  color: black;
  width: 150px;
  height: 40px;
  line-height: 22px;
  border-radius: 40px;
  display: block;
  text-align: center;
  font-size: 16px;
  cursor: pointer;
  border: none;
  font-weight: 600;
}

/* ============top ten vote page =========== */
.topten-main {
  padding-top: 80px;
  padding-bottom: 80px;
}

.topbtn {
  display: flex;
  justify-content: center;
}

.topbtn button {
  font-size: 18px;
  line-height: 25.2px;
  font-weight: 700;
  border-radius: 40px;
  padding: 10px 30px;
  background: linear-gradient(to top, #FD5526, #FFAE1F);
  border: none;
  text-align: center;
  color: #ffffff;
}

.topten-main .top-tenvote .head-section h1 {
  font-size: 70px;
  line-height: 70px;
  color: rgb(255, 255, 255);
  font-weight: 700;
}

.topten-main .top-tenvote .head-section .headdesc {
  gap: 5px;
}

.topten-main .top-tenvote .head-section .headdesc span,
.topten-main .top-tenvote .head-section .headdesc p {
  font-size: 18px;
  line-height: 25.2px;
  color: #ffffff;
}

.topten-main .top-tenvote .awardsection .pricesection {
  margin: 60px 0;
}

.mobileprice,
.vector1 {
  display: none;
}

.desktopprice,
.vector {
  display: block;
}

.topten-main .top-tenvote .head-section .headdesc p {
  font-size: 18px;
  line-height: 20.2px;
  color: #ffffff;
}

/* countdown css */
.countdown {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 120px;
  font-family: Arial, sans-serif;
}

.months-days {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  font-family: Arial, sans-serif;
}


.ho-min-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  font-family: Arial, sans-serif;
}

.colon {
  font-size: 65px;
  font-weight: bold;
  margin-bottom: 25px;
}

.time-box {
  text-align: center;
  background-color: #492719;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 85px;
}

.time {
  display: block;
  font-size: 3.5rem;
  font-weight: bold;
  color: #fff;
  padding: 8px 8px 0px 8px;
}

.title {
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.label {
  font-size: 1rem;
  color: #492719;
}

.topcontain .containsection .wildcardtext {
  text-align: center;
  display: flex;
  justify-content: center;
}

.topcontain .containsection h4 {
  font-size: 70px;
  line-height: 84px;
  font-weight: 700;
  max-width: 820px;
  text-align: center;
  text-transform: uppercase;
}

.topcontain .containsection p {
  font-size: 20px;
  line-height: 25.2px;
  font-weight: 400;
  text-align: center;
}

.city-search,
.search {
  width: 45%;
  /* padding: 0 100px; */
}

.input-group-prepend {
  height: 50px;
}

.citydropdown select,
.search .search-input input {
  background-color: transparent;
  border-bottom: 1px solid rgba(255, 84, 2, 0.2);
  border-top: none;
  border-left: none;
  border-right: none;
  font-size: 18px;
  line-height: 24px;
  font-weight: 600;
  color: #fff;
  padding: 10px 0px;
}

.citydropdown select option {
  background-color: #202020;
  color: #fff;
}

select:focus-visible,
input:focus-visible {
  outline-offset: 0px;
  outline: none;
}

/* conditate table */
.first-item {
  background-image: url('/public/images/medal1.png');
  border-radius: 100%;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-left: 15px;
}

.second-item {
  background-image: url('/public/images/medal2.png');
  border-radius: 100%;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-left: 15px;
}

.third-item {
  background-image: url('/public/images/medal3.png');
  border-radius: 100%;
  height: 46px;
  width: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-left: 15px;
}

.conditatedata .datatable table .table-head tr th {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5rem;
  padding-bottom: 35px;
}

.conditatedata .datatable table tbody {
  border: 1.14px solid rgba(255, 84, 2, 0.2);
  border-radius: 20px;
  padding: 0px 30px;
  background-color: rgba(255, 84, 2, 0.0705882353);
}

.conditatedata .datatable table tbody .candidate {
  gap: 1.5rem;
}

.conditatedata .datatable table tbody .candidate h4 {
  font-size: 20px;
  line-height: 25.2px;
  font-weight: 700;
}

.conditatedata .datatable table tbody .candidate img {
  height: 82px;
  width: 82px;
  border-radius: 10px;
}

.conditatedata .datatable table tbody tr .paddingt-left {
  padding-left: 30px
}

.conditatedata .datatable table tbody tr .paddingt-right {
  padding-right: 30px;
}

.conditatedata .datatable table tbody tr .padding-top-bottom {
  padding-top: 30px;
  padding-bottom: 30px;
}

.bottom-border {
  width: 100%;
  background: #000000;
  height: 1px;
  position: relative;
  z-index: 9999;
  margin-left: 30px;
  margin-right: 30px;
}

.address {
  font-size: 16px;
  line-height: 22.2px;
}

.break-point,
.card-heading {
  display: none;
}

/* ========vote Page =========== */
/* .votebtn button {
  padding: 12px 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 80px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFFFFF;
  background: transparent;
} */

.icons1 {
  height: 100%;
}

.vote-cobtn {
  gap: 10px;
}

.padding-right-votebtn {
  padding-right: 30px;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.buttonTrue {
  display: inline-block;
  cursor: not-allowed !important;
  padding: 12px 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 80px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFFFFF;
  background: #FF5402;
}

.buttonFalse {
  display: inline-block;
  cursor: pointer;
  padding: 12px 50px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 80px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
  color: #FFFFFF;
  background: transparent;
}

/* ========footer css ========= */
.footer {
  padding-top: 100px;
  padding-bottom: 100px;
}

.footer-logo img {
  max-width: 200px;
}

.address-section .information-heading h4 {
  color: #ffffff;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 18px;
  font-weight: 700;
}

.address-section .information-contain p {
  color: #ffffff;
}

.footer-menu {
  display: flex;
  gap: 20px;
  justify-content: end;
}

/* bottom footer */
.copyright {
  background-color: #000000;
  padding-top: 20px;
  padding-bottom: 10px;
}

.copyright-menu ul {
  gap: 15px;
}

.copyright-menu ul li a {
  color: #ffffff;
  text-decoration: none;
}

.copyright-menu ul li {
  color: #ffffff;
  list-style: none;
}

.developby p a,
.reserved p {
  color: #ffffff;
  text-decoration: none;
}

.copyright a:hover,
.copyright .copyright-menu li a:hover {
  color: #f97316;
}




/* // popup css terms & condition */

.termspopup-container .optionbutton {
  margin-top: 20px;
  text-align: center;
}

.termspopup-container .optionbutton button {
  background-color: transparent;
  color: #000;
  border-bottom: 1px solid #000;
  border-top: none;
  border-left: none;
  border-right: none;
  font-weight: bold;
}

.termspopup-container .optionbutton button.active {
  color: blue;
  font-weight: bold;
}

.termsandcon {
  gap: 10px;
}

.termspopup-container .optioninput h6 {
  color: #000;
}

.termspopup-container {
  background-color: #f2eaea;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  width: 470px;
  padding: 15px 15px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10000;
  color: #000;
  /* // display: none; */
}

.termspopup-container h3 {
  color: #000;
  text-transform: capitalize;
  font-weight: bold;
  text-align: center;
  font-size: 25px;
}

.termspopup-container .termsandcondition li {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  opacity: 70%;
  padding-left: 4px;
  padding-right: 4px;
  font-weight: 600;
  list-style: disc;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.termsandcondition .contant {
  list-style: none;
}

.termsandcondition {
  gap: 12px;
}

.termspopup-container .closebtn {
  text-align: center;
}

.termspopup-container .closebtn button {
  background-color: rgba(255, 84, 2, 1);
  padding: 10px 40px;
  border: transparent;
  border-radius: 80px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.termspopup-container .scroll-text {
  height: 200px;
  overflow-y: auto;
}

.popup {
  display: none;
}

.popup.open {
  display: block;
}

.successpopup {
  display: none;
}

.successpopup.open {
  display: block;
}

.successpopup-container {
  display: none;
}

.popup-container h4 {
  color: #000;
  font-size: 20px;
}

.popup-container h3 {
  color: #5e7b1e;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  font-size: 25px;
}

.profile-detail label {
  font-size: 14px;
  line-height: 20px;
  color: #000;
  opacity: 70%;
}

.profile-detail {
  gap: 12px;
}

.profile-detail img {
  height: 70px;
  width: 70px;
}

.closebtn {
  text-align: center;
}

.closebtn button {
  background-color: rgba(255, 84, 2, 1);
  padding: 10px 40px;
  border: transparent;
  border-radius: 80px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  text-align: center;
}

.closebtnhome button {
  background-color: transparent;
  border: transparent;
  position: absolute;
  right: -15px;
  top: -18px;
}

.gif img {
  width: 80px;
  height: 80px;
}

.gif {
  text-align: center;
}

@media only screen and (max-width:260px) {

  .termspopup-container {
    width: 90%;
    padding: 15px;
  }

  .termspopup-container .note {
    font-size: 16px;
    line-height: 14px;
  }

  .termspopup-container h3 {
    font-size: 16px;
  }

  .termsandcondition li {
    font-size: 12px;
  }
}

@media (min-width:260px) and (max-width:320px) {

  .termspopup-container {
    width: 90%;
    padding: 15px;
  }

  .termspopup-container .note {
    font-size: 22px;
    line-height: 22px;
  }

  .termspopup-container h3 {
    font-size: 17px;
  }

  .termsandcondition li {
    font-size: 12px;
  }
}

@media (min-width:320px) and (max-width:640px) {

  .termspopup-container {
    width: 280px;
    padding: 15px;
  }

  .termspopup-container .note {
    font-size: 22px;
    line-height: 22px;
  }

  .termsandcondition li {
    font-size: 12px;
  }

  .termspopup-container h3 {
    font-size: 18px;
  }

  .popup-container h4 {
    color: #000;
    font-size: 18px;
    opacity: 90%;
  }

  .profile-detail img {
    height: 68px;
    width: 68px;
  }
}

@media (min-width:640px) and (max-width:768px) {

  .termspopup-container {
    width: 350px;
    padding: 15px;
  }

  .termspopup-container .note {
    font-size: 22px;
    line-height: 22px;
  }

  .termspopup-container h3 {
    font-size: 25px;
  }

  .popup-container h4 {
    color: #000;
    font-size: 20px;
    opacity: 90%;
  }

  .profile-detail img {
    height: 70px;
    width: 70px;
  }
}


@media (min-width:768px) and (max-width:992px) {
  .time-box {
    width: 65px;
  }

  .time {
    font-size: 2.8rem;
    padding: 0px 8px 0px 8px;
  }

  .label {
    font-size: 0.9rem;
  }
}

@media (min-width:640px) and (max-width:768px) {
  .countdown {
    gap: 70px;
  }

  .months-days {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-family: Arial, sans-serif;
  }

  .ho-min-sec {
    gap: 15px;
  }

  .time-box {
    width: 55px;
  }

  .time {
    font-size: 2.2rem;
    padding: 0px 8px 0px 8px;
  }

  .label {
    font-size: 0.8rem;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media (min-width:310px) and (max-width:640px) {
  .countdown {
    display: block;
    justify-content: center;
    align-items: center;
    gap: 70px;
    font-family: Arial, sans-serif;
  }

  .months-days {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-family: Arial, sans-serif;
  }

  .ho-min-sec {
    gap: 15px;
    margin-top: 15px;
  }

  .colon {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .time-box {
    width: 58px;
  }

  .time {
    font-size: 1.8rem;
    padding: 5px 8px 0px 8px;
  }

  .label {
    font-size: 0.8rem;
    padding-left: 2px;
    padding-right: 2px;
  }
}

@media only screen and (max-width:310px) {
  .countdown {
    display: block;
    justify-content: center;
    align-items: center;
    gap: 70px;
    font-family: Arial, sans-serif;
  }

  .months-days {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    font-family: Arial, sans-serif;
  }

  .ho-min-sec {
    gap: 10px;
    margin-top: 15px;
  }

  .title {
    height: 16px;
    display: flex;
    justify-content: center;
  }

  .colon {
    font-size: 40px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  .time-box {
    width: 45px;
  }

  .time {
    font-size: 1.3rem;
    padding: 8px 8px 0px 8px;
  }

  .label {
    font-size: 0.6rem;
    padding-left: 2px;
    padding-right: 2px;
  }
}

/* profile page css */
.profile-data {
    border: 1px solid #ffffff40;
    border-radius: 10px;
    max-width: 450px;
    padding: 30px;
}

.profile-content {
  display: flex;
  margin-bottom: 10px;
}

.profile-content h5 {
  width: 200px;
}

.profile-content span {
  font-size: 18px;
  font-weight: 500;
}

/* about us css */

.more-about {
  padding-top: 100px;
}

.more-about-des h4 {
  font-weight: 700;
  color: #fff;
}

.more-about-des p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
color: #fff;
}

.about-des-mid {
  padding-top: 60px;
}

.about-des-mid p {
  font-size: 16px;
  font-weight: 400;
  line-height: 28.8px;
  color: #fff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.homeimg {
  padding-top: 60px;
}

.visionary {
  padding-top: 100px;
}

.secTitle h2 {
  font-size: 54px;
  font-weight: 700;
  line-height: 81px;
  color: #fff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.name {
  font-size: 20px;
  font-weight: 700;
  line-height: 36px;
  color: #fff;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.person p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #fff;
}


@media only screen and (max-width:640px) {
  .more-about {
      padding-top: 40px;
  }

  .homeimg {
      padding-top: 15px;
  }

  .visionary {
      padding-top: 30px;
  }

  .more-about-des p {
      font-size: 14px;
      font-weight: 400;
      line-height: 21.8px;
  }

  .about-des-mid p {
      font-size: 14px;
      font-weight: 400;
      line-height: 21.8px;
  }

  .secTitle h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
  }
}

@media (min-width:640px) and (max-width:768px) {
  .more-about {
      padding-top: 40px;
  }

  .homeimg {
      padding-top: 15px;
  }

  .visionary {
      padding-top: 30px;
  }

  .more-about-des p {
      font-size: 15px;
      font-weight: 400;
      line-height: 21.8px;
  }

  .about-des-mid p {
      font-size: 15px;
      font-weight: 400;
      line-height: 21.8px;
  }

  .secTitle h2 {
      font-size: 40px;
      font-weight: 700;
      line-height: 50px;
  }
}

@media (min-width:768px) and (max-width:992px) {
  .name {
      font-size: 18px;
      font-weight: 700;
      line-height: 24px;
  }

  .visionary {
      padding-top: 50px;
  }

  .more-about {
      padding-top: 50px;
  }
  
}

/* private policy css */

.private_policy_wepper {
  background-color: black;
}

.hedding_main {
  -webkit-box-shadow: 5px 5px 15px 1px rgba(255, 255, 255, 1);
  -moz-box-shadow: 5px 5px 15px 1px rgba(255, 255, 255, 1);
  box-shadow: 0px 2px 28px -7px rgba(255, 255, 255, 1);
  padding: 26px 0;
}

.hedding_main h2 {
  color: #fff;
  font-size: 45px;
  margin: 0;
  text-align: center;

}

/* .private_hedding_wepper {
  padding: 50px;
} */

.private_hedding h3 {
  font-size: 30px;
  margin: 0 0 20px;
  /* text-align: center; */
  color: #fff;
}

.private_hedding p {
  font-size: 16px;
  color: #fff;
  /* text-align: center; */
  line-height: 24px;
  margin: 0 0 10px;
}

.private_hedding h4 {
  color: #fff;
}

.private_hedding span,
.private_hedding h5 {
  color: #fff;
  font-weight: bold;
  /* text-align: center; */
  margin-bottom: 20px;
  display: block;
  font-size: 16px;
}

.form-control:focus {
  background-color: transparent !important;
  border-color: #86b7fe;
  outline: none;
  box-shadow: none;
}

.mobileinput {
  border: 1px solid #ffffff70;
  border-radius: 6px;
}

.mobileinput input {
  background-color: transparent;
  color: #fff;
  border: none;
}

.mobileinput input::placeholder{
  color: #ffffff90;
}

.mobileinput .input-group-text {
  border-bottom: none;
  border-left: none;
  border-top: none;
  border-radius:  1px solid #ffffff70 !important;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.popup-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 300px;
  text-align: center;
}