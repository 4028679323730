.shiproket_popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
}

.shiproket_popup_content {
    background: #fffafa;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    position: relative;
    width: 100%;
    max-width: 300px;
    height: 100%;
    max-height: 170px;
}

.close {
    position: absolute;
    top: 0px;
    right: 10px;
    cursor: pointer;
    font-size: 24px;
}

.otp_input input{
    border: 1px solid #000;
    padding: 5px 10px;
    border-radius: 4px;
    width: 100%;
}

.verify_otp_btn {
    padding: 5px 20px;
    background: #ee761a;
    color: #fff;
    font-weight: 400;
    border: 1px solid #ee761a;
    border-radius: 4px;
    width: 100%;
}