.resend_otp_button {
    display: flex;
    gap: 10px;
    align-items: center;
}

.resend_otp_button p {
    color: #ffffff;
}

.resend_otp_button button {
    background: transparent;
    color: #f97316;
    border: none;}