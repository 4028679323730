.elogin_form_main {
    background-color: #ffffff;
    min-height: 100vh;
    display: flex;
    justify-content: center;
}

.elogin_form_main .elogin_form .app_form input[type=text],
textarea ,
.elogin_form_main .elogin_form .app_form input[type=email],
.elogin_form_main .elogin_form .app_form input[type=date],
.elogin_form_main .elogin_form .app_form input[type=password]
{
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 1rem;
    padding: 10px;
    transition: border-color .3s ease;
    width: 100%;
}

.elogin_form_main .elogin_form .app_form{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 20px;
}

.elogin_form_main .event_buttonX {
    background-color: #d73d55;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 1.1rem;
    padding: 10px;
    transition: background-color .3s ease;
    width: 100%;
    margin-top: 5px;
}