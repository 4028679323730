/* General Styling */
body {
    font-family: 'Arial', sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f5f5f5;
}

.youthfestival h1 {
    text-align: center;
    color: #ffffff;
    font-size: 2rem;
    margin-bottom: 20px;
}

.youthfestival h5 {
    color: #ffffff;
    margin-bottom: 5px;
}

/* Form Container Styling */
.youthfestival .main {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.youthfestival .card-contact {
    background-color: #000000;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    width: 100%;
    padding: 20px;
    border: 1px solid #ffffff4d;
}

.youthfestival .cont-form {
    display: flex;
    flex-direction: column;
}

.youthfestival .cont-form>div {
    margin-bottom: 15px;
}

.youthfestival .option {
    color: #000 !important;
}

/* Input and Textarea Styling */
.youthfestival input[type="text"], textarea, .form-control, .passward_field {
    width: 100%;
    padding: 10px;
    border: 1px solid #ffffff4d;
    border-radius: 4px;
    font-size: 1rem;
    margin-top: 5px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
    background-color: transparent;
    color: #fff;
}

.youthfestival .form-control {
    font-size: 1rem;
    color: #fff !important;
}

.youthfestival input[type="text"]:focus,
textarea:focus,
.passward_field:focus,
.form-control:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: none;
}

.youthfestival textarea {
    resize: none;
    height: 100px;
}

.select_button select option {
    background-color: #000;
    color: #ffff;
}

/* Button Styling */
.youthfestival .buttonX {
    width: 100%;
    padding: 10px;
    background-color: #d73d55;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1.1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.youthfestival .buttonx:hover {
    color: #d73d55;
    background-color: white;
}

.select_button {
    display: flex;
    gap: 25px;
}

.select_button select {
    height: 100%;
    width: 100%;
    padding: 13px;
    border: 1px solid #ffffff4d;
    border-radius: 4px;
    background-color: transparent;
    color: #ffffff;
}

/* Responsive Design */
@media (max-width: 768px) {
    .youthfestival .card-contact {
        padding: 15px;
    }

    .youthfestival input[type="text"],
    textarea {
        font-size: 0.9rem;
        padding: 8px;
    }

    .youthfestival h1 {
        font-size: 1.75rem;
    }
}

@media (max-width: 576px) {
    .youthfestival h1 {
        font-size: 1.5rem;
    }

    .youthfestival .b {
        font-size: 1rem;
    }
}