.youthfestival .payment_failed  {
    color: #000;
}

.youthfestival  .payment_status {
    background-color: #ffffff;
}

.youthfestival  .payment_status .payment_status_box{
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
    padding: 20px 40px;
    border-radius: 4px;
}

.youthfestival .go_home_btn  button,.youthfestival .go_home_btn a{
    background: #d73d55;
    padding: 10px 20px;
    border-radius: 3px;
    color: #fff;
    font-weight: 500;
    font-size: 17px;
    width: 100%;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40vh;
}

.spinner {
    border: 8px solid rgb(0 0 0 / 36%);
    border-top: 8px solid #0000008c;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}